import * as React from "react"
import { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ResourcesIntroduction from "../components/resourcesIntroduction"
import ResourcesFilters from "../components/resourcesFilters"
import ResourceCard from "../components/elements/resourceCard"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-website-light.jpg"
import Favicon from "../images/favicon.png"
import SearchBar from "../components/searchBar"
import { useFlexSearch } from "react-use-flexsearch"

const isBrowser = typeof window !== "undefined"

const unFlattenResults = results =>
  results.map(post => {
    const {
      id,
      downloads_categories,
      blog_categories,
      webinar_categories,
      featured_image,
      intro_text,
      labels,
      published_at,
      published_at_formatted,
      slug,
      title,
      updated_at,
      time_to_read,
      webinar_date_formatted,
    } = post
    return {
      node: {
        slug,
        id,
        title,
        downloads_categories,
        blog_categories,
        webinar_categories,
        featured_image,
        intro_text,
        labels,
        published_at,
        published_at_formatted,
        slug,
        updated_at,
        time_to_read,
        webinar_date_formatted,
      },
    }
  })

const ResourcesPage = ({ data, ...props }) => {
  // check if window is defined
  if (isBrowser) {
    var { index, store } = data.localSearchPages
    var { search } = window.location
  }

  const query = new URLSearchParams(search).get("s")
  const [searchQuery, setSearchQuery] = useState(query || "")

  const blogs = data.allStrapiBlogs.edges
  const webinars = data.allStrapiWebinars.edges
  const downloads = data.allStrapiDownloads.edges
  const allResources = blogs
    .concat(webinars, downloads)
    .sort(
      (a, b) => new Date(b.node.published_at) - new Date(a.node.published_at)
    )

  const [list, setList] = useState([...allResources.slice(0, 9)])
  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)
  // State to check whether there is more to load
  const [hasMore, setHasMore] = useState(allResources.length > 9)
  // const [searchHasMore, setSearchHasMore] = useState(results?.length > 9)

  // Get the search results from user input
  const results = useFlexSearch(searchQuery, index, store)
  // If a user has typed in a query, use the search results. Otherwise, use all posts
  const posts = searchQuery ? unFlattenResults(results) : list

  // If the searchbar is used, the state to false
  const [isDefault, setIsDefault] = useState(true)

  // TODO: Refactor the code and rewrite load more logic after using the search bar

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }
  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allResources.length
      const nextResults = isMore
        ? allResources.slice(currentLength, currentLength + 9)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allResources.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  useEffect(() => {
    if (searchQuery === "") {
      setIsDefault(true)
    } else {
      setIsDefault(false)
    }
  }, [results])

  return (
    <Layout>
      <div className="page resources">
        <Seo
          title="Loyall – Wij zijn een digital lifecycle agency"
          image={{ src: MetaImage, height: 628, width: 1200 }}
          description="We hebben de complete digitale mix in huis om jouw bedrijf duurzaam te laten groeien. Van marketing tot development, van design tot integraties. Met de digitale lifecycle investeren we in krachtige relaties met je doelgroep."
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <ResourcesIntroduction
          title="All you need for a head start"
          subtitle="Resources"
          padding={{ bottom: 32, top: 80, left: 15, right: 15 }}
        />
        <ResourcesFilters />

        <div className="component container resources_overview">
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <Row>
            <Col md={12} className="resources_wrapper">
              {posts.map((resource, i) => {
                let name = resource.node.id.slice(
                  0,
                  resource.node.id.lastIndexOf("_")
                )

                // If posts length is above 0, show the cards
                return (
                  <ResourceCard
                    key={i}
                    id={i}
                    name={name}
                    resource={resource.node}
                  />
                )
              })}
              {/* If there are no results, show the NoResults component */}
              {posts.length === 0 && <NoResults />}
            </Col>
          </Row>
          <div className="text-center pt-4">
            {hasMore && isDefault ? (
              <button
                onClick={handleLoadMore}
                className="btn btn-solid btn-loyall"
              >
                Meer laden
              </button>
            ) : (
              <p></p>
            )}
          </div>
        </div>
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </div>
    </Layout>
  )
}

function NoResults() {
  return (
    <div>
      <h2>Geen resulaten gevonden</h2>
    </div>
  )
}

export const query = graphql`
  query AllResourcesQuery {
    localSearchPages {
      index
      store
    }
    allStrapiBlogs(sort: { fields: published_at, order: DESC }) {
      edges {
        node {
          id
          title
          time_to_read
          strapiId
          slug
          published_at
          published_at_formatted: published_at(
            formatString: "DD MMMM YYYY"
            locale: "nl"
          )
          updated_at(formatString: "DD MMMM YYYY", locale: "nl")
          featured_image {
            alternativeText
            url
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
          blog_categories {
            id
            name
            slug
          }
          labels {
            color
            name
            id
            slug
          }
        }
      }
    }
    allStrapiDownloads(sort: { fields: published_at, order: DESC }) {
      edges {
        node {
          id
          strapiId
          title
          slug
          intro_text
          published_at
          published_at_formatted: published_at(
            formatString: "DD MMMM YYYY"
            locale: "nl"
          )
          updated_at(formatString: "DD MMMM YYYY", locale: "nl")
          labels {
            color
            name
            id
            slug
          }
          featured_image {
            alternativeText
            url
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
          downloads_categories {
            slug
            name
            id
          }
          labels {
            color
            name
            id
            slug
          }
        }
      }
    }
    allStrapiWebinars(sort: { fields: published_at, order: DESC }) {
      edges {
        node {
          id
          intro_text
          slug
          strapiId
          title
          webinar_url
          webinar_date
          webinar_date_formatted: webinar_date(
            formatString: "DD MMMM YYYY"
            locale: "nl"
          )
          published_at
          published_at_formatted: published_at(
            formatString: "DD MMMM YYYY"
            locale: "nl"
          )
          updated_at(formatString: "DD MMMM YYYY", locale: "nl")
          featured_image {
            alternativeText
            url
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
          labels {
            color
            name
            id
            slug
          }
        }
      }
    }
  }
`

export default ResourcesPage
