import React from "react"
import { Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <form action="/" method="get" autoComplete="off" className="search">
    <label htmlFor="header-search">
      <span className="visually-hidden sr-only">Search blog posts</span>
    </label>
    <input
      value={searchQuery}
      onInput={e => setSearchQuery(e.target.value)}
      type="text"
      id="header-search"
      placeholder="Zoek op trefwoord of onderwerp"
      name="s"
      className="search--input"
    />
    <button type="button" className="search--btn">
      <FontAwesomeIcon icon={faSearch} size="lg" />
    </button>
  </form>
)

export default SearchBar
